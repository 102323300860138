<template>
  <div
    class="Herovalueproposition"
  >
    <h1>
      <slot />
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Herovalueproposition'
}
</script>

<style scoped>
.Herovalueproposition h1{
  /* background-color:white; */
  padding:1vh;
  font-size: 3vh;
  border-radius: 15px;
  color:black;
  max-width: 20em;
}
</style>
