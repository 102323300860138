<template>
  <div
    class="Herocard"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Herocard'
}
</script>

<style scoped>
.Herocard{
  background-color:white;
  padding:1vh;
  max-width: 40em;
  border-radius: 1em;
}
</style>
