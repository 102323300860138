<template>
  <div
    class="Herosearch"
  >
    <v-text-field 
      filled
      append-icon="mdi-magnify"
      :placeholder="placeholder"
      :hint="hint"
      persistent-hint
      label="Search"
    />
  </div>
</template>

<script>
export default {
  name: 'Herosearch',
  props: {
    placeholder: {
      type: String,
      default: "Items"
    },
    hint: {
      type: String,
      default: "Example: Things"
    }
  }
}
</script>

<style scoped>
/* .Herosearch {

} */
</style>
