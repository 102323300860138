<template>
  <div
    class="Homehero"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Homehero',
}
</script>

<style scoped>
.Homehero {
  height: 70vh;
  background-color: var(--v-primary-base);
  padding-right:1vh;
  padding-left:1vh;
  padding-top:1vh;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: center;
}
</style>
