<template>
  <div class="Valueproposition">
    <div>
      <div
        height="20vh"
      >
        <v-img
          contain
          max-height="20vh"
          :src="getPic(illustration)"
        ></v-img>
      </div>
      <div>
        <h2>
          {{
            description
          }}
        </h2>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
export default {
  name: 'Valueproposition',
  methods: {
    getPic(
      illustration
    ) {
      return (
        require('@/assets/' + illustration)
      )
    }
  },
  props: {
    description: {
      type: String,
      default: 'You will benefit greatly from using our product'
    },
    illustration: {
      type: String,
      default: 'undraw_gift1_sgf8.svg'
    }
  }
}
</script>

<style scoped>
.Valueproposition {
  text-align: center;
  max-width: 30em;
  margin:auto;
  margin-top:1em;
  margin-bottom:1em;
}
</style>
