<template>
  <div 
    class="home"
  >
    <Homehero
    >
      <Herocard>
        <Herovalueproposition>
          Explore World Facts From Original and Diverse Perspectives
        </Herovalueproposition>
        <Herosearch 
          placeholder=""
          hint="Example: Borders"
        />
      </Herocard>
      <br>
      <div 
        height="30vh"
        
      >
        <v-img
          max-height="30vh"
          contain
          class="d-flex my-2"
          src="@/assets/undraw_connected_world_wuay.svg"
        />
      </div>
    </Homehero>
    <Valuepropositionslist>
      <!-- <Valueproposition /> -->
      <Valueproposition 
        illustration="undraw_visual_data_b1wx.svg"
        description="Gain New Insights from Visual Summaries"
      />
    </Valuepropositionslist>
  </div>
</template>

<script>
// @ is an alias to /src
import Homehero from '@/components/Homehero.vue'
import Herocard from '@/components/Herocard.vue'
import Herovalueproposition from '@/components/Herovalueproposition.vue'
import Herosearch from '@/components/Herosearch.vue'
import Valuepropositionslist from '@/components/Valuepropositionslist.vue'
import Valueproposition from '@/components/Valueproposition.vue'


export default {
  name: 'Home',
  components: {
    Homehero,
    Herocard,
    Herovalueproposition,
    Herosearch,
    Valuepropositionslist,
    Valueproposition
  },
}
</script>

