<template>
  <div class="Valuepropositionslist">
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Valuepropositionslist'
}
</script>

<style scoped>
.Valuepropositionslist {
  padding: 1em;
}
</style>
